import React from 'react';
import PropTypes from 'prop-types';

/**
 * @onitTitle OnitSpinner
 * @onitChapter 2.2.13
 * @onitDoc
 *
 * Show a rotating spinner
 * ```js
 * import {OnitSpinner} from  '@mitech/onit-next-react-components/dist/spinner/OnitSpinner'
 * <OnitSpinner className={somethingIfNeeded}>
 * ```
 *
 * @param {string} className Css classes to be applied to the item
*/
export class OnitSpinner extends React.PureComponent {
    static propTypes = {
        className: PropTypes.string
    }

    render = () =>
        <i className={'text-gray text-medium fas fa-circle-notch fa-spin ' + (this.props.className || '')}></i>
}
