import './Forms.css';
import React from 'react';
import PropTypes from 'prop-types';

/**
 * @onitTitle OnitCheckbox
 * @onitChapter 2.2.1
 * @onitDoc
 *
 * Onit generic checkbox
 *
 * ```js
 * import {OnitCheckbox} from  '@mitech/onit-next-react-components/dist/forms/OnitCheckbox'
 * <OnitCheckbox
 *     onChange={this.onChange}
 *     label={this.props.title}
 *     checked={this.props.checked}>
 * </OnitCheckbox>
 * ```
 * @param {bool} readOnly Make this field not editable
 * @param {bool} disabled Set the field as blocked & non editable (with custom classes and behaviors)
 * @param {string} id Id for the underlaying html5 checkbox. Generated Automatically if none is passed in
 * @param {func} label Label to be displayed close to the checkbox
 * @param {[items]} onChange Onchange event as defined by react input handling. Must be a function accepting two parameters:
 * ```js
 * onchange(event, source){}
 * ```
 * @param {bool} className Css class names attached to the html5 input tag
 * @param {bool} checked Checkbox value
 * @param {any} source Generic object returned with the onChange function as second parameter
 */
export class OnitCheckbox extends React.PureComponent {
    static propTypes = {
        id: PropTypes.string,
        readOnly: PropTypes.bool,
        label: PropTypes.string,
        onChange: PropTypes.func,
        className: PropTypes.string,
        checked: PropTypes.bool,
        source: PropTypes.any,
        disabled: PropTypes.bool
    };

    constructor (props) {
        super(props);
        this.id = this.props.id || 'OnitCheckbox-' + Math.floor(Math.random() * Number.MAX_SAFE_INTEGER);
    }
    /*
        onChange = (event) => {
            if (this.props.onChange && (this.props.readOnly !== true)) {
                this.props.onChange(event);
            }
        }

        render = () =>
            <div className={"form-group form-check " + (this.props.className || '')}>
                <input
                    type="checkbox"
                    style={{ width: 15, height: 15 }}
                    checked={this.props.checked}
                    value={this.props.ckecked}
                    onChange={this.onChange}
                    readOnly={this.props.readOnly === true}
                    className="form-check-input"
                    id={this.id} />
                <label className={"form-check-label " + (this.props.label ? "pl-1" : '')} htmlFor={this.id}>{this.props.label || ''}</label>
            </div>
    */
    /*
    font-awesome version:
    FIXME: can't manage disabled fieldset dannazione!
           Sarebbe il piu carino (perchè ha i colori del portale) ma deve gestire i fieldset disabilitati

    onClick = (event) => {
        if (this.props.readOnly !== true) {
            // For compatibility with the bootstrap version: access current checked status via event.target.checked
            event.target = { checked: !this.props.checked };
            this.props.onChange(event);
        }
    }
    render = () => <span onClick={this.onClick}>
        <i className={"pr-2 text-primary far " + (this.props.checked ? 'fa-check-square' : 'fa-square') + (this.props.readOnly ? '' : ' cursor-pointer')}></i>
        {this.props.label ? <span className="pl-2">{this.props.label}</span> : ''}
    </span>
    */

    /*
    bootstrap version
    Nice look&feel but some rendering issues
    */
    onChange = (event) => {
        if (this.props.onChange && this.props.readOnly !== false && !this.props.disabled) {
            this.props.onChange(event, this.props.source);
        }
    };

    getClassName = () => {
        let className = 'custom-control custom-checkbox ' + (this.props.className || '');
        if (this.props.disabled) className = className + ' disabled';

        return className;
    };

    render = () =>
        <div className={this.getClassName()}>
            <input
                type="checkbox"
                className="custom-control-input"
                id={this.id}
                readOnly={this.props.readOnly || this.props.disabled}
                checked={this.props.checked}
                onChange={this.onChange}
            />
            <label className="custom-control-label" htmlFor={this.id}>
                {this.props.label || ''}
            </label>
        </div>;
}
