import React from 'react';
import ReactDOM from 'react-dom';
import LoginTemplatePage from '../../../../components/react/loginTemplatePage';
import PropTypes from 'prop-types';
import { OnitCheckbox } from '@mitech/onit-next-react-components/dist/forms/OnitCheckbox';
import { OnitSpinner } from '@mitech/onit-next-react-components/dist/spinner/OnitSpinner';
import utility from 'utility';
import { OnitButton } from '@mitech/onit-next-react-components/dist/buttons/OnitButton';

class Login extends React.PureComponent {
    static propTypes = {
        errorMessage: PropTypes.string,
        leftBackground: PropTypes.string,
        leftCustomHtml: PropTypes.string,
        leftLogo: PropTypes.string,
        passportStartegies: PropTypes.arrayOf(
            PropTypes.shape({
                icon: PropTypes.string,
                label: PropTypes.string,
                url: PropTypes.string,
                class: PropTypes.string,
                style: PropTypes.object
            })
        ),
        rightLogo: PropTypes.string
    };

    constructor (props) {
        super(props);
        this.state = {
            errorMessage: this.props.errorMessage,
            username: '',
            password: '',
            remember: false
        };
    }

    usernameChange = (e) => {
        this.setState({ username: e.target.value });
    }

    passwordChange = (e) => {
        this.setState({ password: e.target.value });
    }

    rememberChange = (e) => {
        this.setState({ remember: e.target.checked });
    }

    renderError = () =>
        this.state.errorMessage
            ? <div className="alert alert-danger cup text-center p-2 mt-3 text-small" role="alert">{this.state.errorMessage}</div>
            : ''

    submit = () => {
        this.setState({ asyncWorkRunning: true, errorMessage: false });

        utility.http.POST('/auth/local', {
            username: this.state.username,
            password: this.state.password,
            remember: this.state.remember ? 'on' : 'off'
        })
            .then(() => {
                location.href = '/auth/login';
            })
            .catch(e => {
                this.setState({
                    errorMessage: utility.errorInfo(e),
                    asyncWorkRunning: false
                });
            });
    }

    handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            this.submit();
        }
    }

    renderPassportStrategies = () =>
        this.props.passportStartegies.map(s =>
            <button
                key={s.label + s.url}
                style={Object.assign({ height: 40 }, s.style || {})}
                className={'w-100 btn btn-sm ' + (s.class || '')}
                onClick={() => { location.href = s.url; }}>
                <img className='float-left' src= {s.icon}></img>
                {utility.i18n(s.label)}
            </button>);

        render = () =>
            <LoginTemplatePage
                rightLogo={this.props.rightLogo}
                leftLogo={this.props.leftLogo}
                leftBackground={this.props.leftBackground}
                leftCustomHtml={this.props.leftCustomHtml}
            >
                <div className="text-center">
                    <img src={this.props.rightLogo} className='logo' />
                </div>
                <p className="text-muted text-center mb-5">
                    {utility.i18n('Login.welcome')}
                </p>

                {this.renderError()}

                <div className="form-group">

                    <input
                        type="text"
                        name="username"
                        value={this.state.username}
                        onChange={this.usernameChange}
                        onKeyPress={this.handleKeyPress}
                        placeholder={utility.i18n('Login.usernamePlaceholder')}
                        className='form-control' />
                </div>

                <div className="form-group pt-3">

                    <input
                        type="password"
                        value={this.state.password}
                        onChange={this.passwordChange}
                        onKeyPress={this.handleKeyPress}
                        name="password"
                        placeholder={utility.i18n('Login.passwordPlaceholder')}
                        className='form-control' />

                </div>

                <div className="row pt-4">
                    <div className="col">
                        <OnitCheckbox
                            checked={this.state.remember}
                            label={utility.i18n('Login.remember')}
                            onChange={this.rememberChange}>
                        </OnitCheckbox>
                    </div>
                    <div className="col-auto">
                        <a href="/auth/recoverPassword" className="form-text small text-muted">
                            {utility.i18n('Login.forgotPassword')}
                        </a>
                    </div>
                </div>

                <span className='d-block' style={{ maxWidth: '150px' }}>
                    <OnitButton onClick={this.submit} disabled={this.state.asyncWorkRunning} className={'btn btn-primary mt-3 w-100'}>
                        {this.state.asyncWorkRunning ? <OnitSpinner></OnitSpinner> : utility.i18n('Login.login') }
                    </OnitButton>
                </span>
                <div className='pt-4'>
                    {this.renderPassportStrategies()}
                </div>

            </LoginTemplatePage>
}

ReactDOM.render(<Login {...window.exportedPageData}/>, document.getElementById(window.exportedPageData.renderContainerId));
