import React from 'react';
import PropTypes from 'prop-types';

/**
 * @onitTitle OnitButton
 * @onitChapter 2.2.3
 * @onitDoc
 *
 * Generic bootstrap based button. This component implement a basic button with some added logic
 *
 * ```js
 * import {OnitButton} from  '@mitech/onit-next-react-components/dist/buttons'
 * <OnitButton
 *    className="mt-4 btn-success btn-sm"
 *    onClick={this.confirmPasswordChange}
 *    disabled={false}>
 *    This is the button content. You can also add some font Awesome icon <i className='fa fa-cog'></i>
 * </OnitButton>
 * ```
 *
 * @param {boolean} managed If false, do not automatically set the busy state;
 * @param {className} className Css classes to be applied to the button
 * @param {boolean} disabled If true, disable tis button by making it not clickable and slightly less faded out
 * @param {function} onClick Function to be called on btn click. The click event passed to this function will have some additional functions:
 * **event.target.setEnabled(boolean)** set the enabled status for the button
 * **event.target.setWorking(boolean)** set the working state(display a spinner instead of childs) of the button
 * **event.target.ready()** equivalent of setEnabled(true) && setWorking(false)
 * **event.target.busy()** equivalent of setEnabled(false) && setWorking(false)
 *
 */
export class OnitButton extends React.PureComponent {
    static propTypes = {
        children: PropTypes.any,
        disabled: PropTypes.bool,
        managed: PropTypes.bool,
        onClick: PropTypes.func,
        className: PropTypes.string,
        tooltip: PropTypes.string
    }

    constructor (props) {
        super(props);
        this.btnRef = React.createRef();
        this.state = {
            disabled: this.props.disabled,
            working: false
        };
    }

    click = (event) => {
        if (this.props.onClick) {
            // disabled button must do nothing
            if (this.state.disabled) return;

            // this match on disabled fieldsets
            if (event.currentTarget.matches(':disabled')) return;

            // persisting event will enable us to add custom data to the event
            event.persist();

            // pass back the event to the parent if needed
            if (this.props.managed === true) { this.setState({ disabled: true, working: true }); }

            event.target.setEnabled = (b) => {
                this.setState({ disabled: b === false });
            };
            event.target.setWorking = (b) => {
                this.setState({ working: b });
            };

            event.target.ready = () => {
                this.setState({ disabled: false, working: false });
            };

            event.target.busy = () => {
                this.setState({ disabled: true, working: true });
            };

            this.props.onClick(event);
        }
    }

    /* shouldComponentUpdate = (nextProps, nextState) => {
        let equal = this.state.disabled === nextState.disabled;
        equal = equal && this.state.working === nextState.working;
        equal = equal && this.props.disabled === nextProps.disabled;
        equal = equal && this.props.className === nextProps.className;
        equal = equal && this.props.managed === nextProps.managed;
        return !equal;
    } */

    componentDidUpdate = (previousProps) => {
        const equal = this.props.disabled === previousProps.disabled;
        if (!equal) {
            this.setState({ disabled: this.props.disabled });
        }
    };

    render = () =>
        <button
            title={this.props.tooltip}
            ref={this.btnRef}
            type="button"
            disabled={this.state.disabled}
            onClick={this.click}
            style={{ whiteSpace: 'nowrap' }}
            className={'btn ' + this.props.className}>
            {(this.state.working)
                ? <i className="text-gray text-medium fas fa-circle-notch fa-spin"></i>
                : this.props.children
            }
        </button>
}
